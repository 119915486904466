@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: rgba(189, 31, 105, 255); /*primary color*/
    font-family: "Oswald" !important;
    color: white;
    height: 100vh;
}

button {
    box-shadow: rgba(0, 0, 0, 0.15) 3.4px 3.4px 3.2px;
}

/*
    Mobile view

    Top logo 15%
    Body 70%
    Footer 15%

    Makes it easy to center vertically on page within body
*/

@media (max-width: 767px) {
    .Logo {
        height: 15%;
    }

    .pageBody {
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 83.33333333%;
        margin: 0 auto;
    }

    .Footer {
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}

/*
    Tablet + Desktop View

    Top logo 10%
    Body 80%
    Footer 10% (invisible, but still there for spacing)

*/
@media (min-width: 768px) {
    .Logo {
        height: 10%;
    }

    .pageBody {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        margin: 0 auto;
    }

    .Footer {
        height: 10%;
        display: none;
        flex-direction: column;
        justify-content: end;
    }
}
